@media print {
    .overview {
        /* page-break-after: always; */
        background-color: black;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}

* {
    page-break-inside: avoid;
}
