body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

/* date picker styles customization */
.react-datepicker__day--keyboard-selected {
    background-color: #dfdfdf !important;
    border-radius: 0 !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range {
    background-color: #dfdfdf !important;
    border-radius: 0 !important;
    color: #202020 !important;
}

.react-datepicker__header {
    background-color: white !important;
    border-bottom: 1px solid #f4f4f4 !important;
    /* padding: 0 !important; */
}

.react-datepicker {
    border: none !important;
    color: #202020 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 2rem !important;
    color: #5a5555 !important;
}

.react-datepicker__day-names {
    padding-top: 4px !important;
    margin-top: 16px !important;
    margin-bottom: -4px !important;
    border-top: 1px solid #f4f4f4 !important;
}

.react-datepicker__current-month {
    font-weight: 500 !important;
    color: #555555 !important;
}

.datepicker__year-dropdown {
    padding-top: 20px;
    /* padding-bottom: 20px; */
}

.react-datepicker__year-dropdown
    .react-datepicker__year-option:first-child::before {
    content: "\25B2";
    position: absolute;
    top: 0%;
    left: 45%;
    margin: auto;
}

.react-datepicker__year-dropdown::after {
    content: "\25BC";
    display: block;
    position: relative;
    bottom: 15%;
}

/* ReCharts */

svg .recharts-surface {
    width: 16px;
    height: 16px;
}
/* scroll bar */

/* width */

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #d2cccc;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #a7a4a4;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #a7a4a4;
}

.custom-tooltip {
    background-color: #fff;
    color: #000;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.chart-container {
    position: relative;
    z-index: 0;
    margin: 100px auto 0;
    width: 800px;
    height: 500px;
    /*border: solid 1px #bbb;*/
    background-color: rgba(255, 255, 255, 0.055);
    /*  -webkit-box-shadow: 0 8px 6px -6px #444;
  */
}

.bar {
    float: left;
    position: absolute;
    bottom: 0;
    z-index: 99;
    height: 35%;
    width: 55px;
    margin: 0 40px;
    background-color: #339e73;
    /* border: 1px solid #ffffff; */
}

.bar:before {
    content: "";
    position: absolute;
    left: -15px;
    bottom: 8px;
    height: 100%;
    width: 15px;
    /* border: 1px solid #ffffff; */
    background-color: #3fc08b;
    -webkit-transform: skewY(45deg);
}

.bar:after {
    content: "";
    position: absolute;
    top: -16px;
    left: -8px;
    width: 55px;
    height: 16px;
    /* border: 1px solid #ffffff; */
    background-color: #48d89d;
    -moz-transform: skewX(45deg);
    -o-transform: skewX(45deg);
    -webkit-transform: skewX(45deg);
    transform: skewX(45deg);
}

.bar-0 {
    bottom: 150px;
    left: 25%;
    height: 200px;
    z-index: 1;
}

.bar-1 {
    bottom: 130px;
    left: 12%;
    height: 200px;
    z-index: 2;
}

.bar-2 {
    bottom: 100px;
    left: 35%;
    height: 200px;
    z-index: 3;
}

.bar-3 {
    bottom: 80px;
    left: 21%;
    height: 200px;
    z-index: 3;
}
